import { Component, Input } from '@angular/core';

@Component({
    selector: 'big-ui-spinner',
    templateUrl: './spinner.component.html',
    standalone: false,
})
export class SpinnerComponent {
    @Input() public spinnerClass = 'flex items-center justify-center py-12';
    @Input() public color: 'primary' | 'white' = 'primary';
    @Input() public size: 'm' | 's' = 'm';
}
