import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, signal } from '@angular/core';
import { UserRepository } from '@big-direkt/state/user';
import { UiLinkModule } from '@big-direkt/ui/link';
import { UiSpinnerModule } from '@big-direkt/ui/spinner';
import { UiSvgIconModule } from '@big-direkt/ui/svg-icon';
import { DownloadService } from '@big-direkt/utils/environment';
import { IconBigMediumDateiDownload, IconBigMediumHinweis, IconBigMediumPfeilRechts, IconComponent } from '@big-direkt/utils/icons';
import { LinkListItem } from '@big-direkt/utils/shared';
import { saveAs } from 'file-saver';
import { firstValueFrom, tap } from 'rxjs';

@Component({
    selector: 'big-ui-link-list-item',
    templateUrl: './link-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgTemplateOutlet, NgClass, UiLinkModule, IconComponent, UiSpinnerModule, UiSvgIconModule],
})
export class LinkListItemComponent {
    private readonly downloadService: DownloadService = inject(DownloadService);
    private readonly userRepository = inject(UserRepository);

    public readonly iconArrowRight = IconBigMediumPfeilRechts;
    public readonly iconDownload = IconBigMediumDateiDownload;
    public readonly iconDownloadFailed = IconBigMediumHinweis;

    public readonly isLoading = signal(false);
    public readonly loadingFailed = signal(false);
    private readonly pdfDownloadPath = '/api/dpp/pdf';

    @Input() public item!: LinkListItem;
    @Input() public canGrow = true;
    @Input() public noPadding = false;
    @Input() public parentCount = 0;
    @Input() public downloadLink = false;

    @HostBinding('class') public classList = 'block';

    public onDownloadLinkClick($event: Event, item: LinkListItem): void {
        $event.preventDefault();

        if (this.isLoading()) {
            return;
        }

        this.isLoading.set(true);
        const userToken = this.userRepository.getToken();

        void firstValueFrom(
            this.downloadService.getBlob(`${this.pdfDownloadPath}/${item.pdfFileKey}`, userToken).pipe(
                tap(res => {
                    if (!res) {
                        return;
                    }

                    const { fileName } = item;
                    saveAs(res, fileName);
                }),
            ),
        )
            .catch(() => {
                this.loadingFailed.set(true);
            })
            .finally(() => {
                this.isLoading.set(false);
            });
    }
}
